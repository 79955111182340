/* .App-footer {
  background-color: transparent;
  padding: 0px 0px 15px;
  text-align: center;
  position: relative;
  height: 15px;
  bottom: 0;
  width: 100%;
  z-index: 0;
} */

.App-footer {
  display: flex;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: start;
  line-height: 5;
  font-size: 1.8vw;
  /* position: relative; */
  font-family: 'Montserrat', sans-serif;
  color: rgb(197, 165, 37);
  background-color: rgba(139, 134, 134, 0.351);
  height: 25%;
  width: 100%;
}

.footer-links {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  /* gap: 4vh; */
  padding: 0 4vw;
}

.jd-footer-logo {
  scale: 30%;
  margin-left: -8%;
}

@media (max-width: 480px) {
  .App-footer {
    width: 100%;
    font-size: 3vw;
    /* height: 20%; */
    /* flex-direction: column; */
    height: 20%;
    line-height: 2;
  }
  .footer-links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 4vh; */
  /* padding: 0 4vw; */
  }
}