
.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1% 1%;
  margin-right: 25%;
}


.navbar-brand {
    color: white !important;
    width: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: right;
    scale: 70%;
    /* background-color: rgb(255, 255, 255); */
    margin-top: 2%;
  }

.navbar-brand-img {
  width: 70px;
  height: auto;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-nav.open {
  display: flex;
  flex-direction: row;
  padding-left: 10%;
}

.nav-link {
  color: rgb(29, 29, 29);
  margin-right: 20px;
  font-size: 1.3vw;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  padding-right: 20%;
}

.tossing-coffee {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 99%;
  scale: 110%;
  font-size: 2rem;
  font-weight: 500;
  color: #0b0d0e; 
  animation: toss 3s infinite;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.tossing3 {
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  width: 99%;
  /* scale: 90%; */
  font-size: .8em;
  font-weight: 500;
  color: #0b0d0e; 
  /* animation: toss 3s infinite; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

@keyframes toss {
  0%, 100% { transform: rotate(0.7deg); }
  /* 75% { transform: rotate(-1deg); } */
  /* 0%, 100% { transform: rotate(1.1deg); } */
  75% { transform: rotate(-0.7deg); }
}

.nav-link img {
  width: auto;
  height: 30px;
}

.nav-button {
  color: rgb(197, 165, 37);
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-shadow: 0.29px 0.29px 0.29px rgba(255, 255, 255, 0.564); */
  transition: background-color 0.1s ease, color 0.1s ease;
}

.nav-link:hover .nav-button {
  transform: scale(1.05);
  /* color: rgb(197, 165, 37); */
  text-shadow: none;
  cursor: default;
}


.hamburger-container {
  position: relative;
  padding-top: 22px;
}

.burger {
  width: 2rem;
  height: 0.25rem;
  border-radius: 9px;
  background-color: rgb(197, 165, 37);
  transform-origin: 1px;
  transition: all 0.3s linear;
}
.hamburger {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;
}

.hamburger.open .burger1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .burger2 {
  opacity: 0;
}

.hamburger.open .burger3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* .drawerPaper {
  background-color: transparent;
  color: #ffffff;
  height: 100%;
  flex-direction: row;
  align-items:start;
  justify-content:center;
} */

.navbar-links .nav-link {
  color: rgb(29, 29, 29);
  margin-right: 20px;
  font-size: 0.73rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}


.drawer-links .MuiListItemText-primary {
  font-size: 1rem; /* Adjust font size as needed */
}

.navbar-links .nav-link {
  color: rgb(29, 29, 29);
  margin-right: 20px;
  font-size: 0.73rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}


@media (max-width: 768px) {

  .navbar-nav {
    display: none;
    /* display: flex; */
    flex-direction: row;
    align-items: center;
  }

  .navbar-nav.open {
    display: flex;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    font-size: 0.67cap;
    margin: 0px 10;
  }
}

/* NavHamburger.css */
.hamburger-container {
  position: relative;
  padding-top: 22px;
  padding-left: 80%;
}

.hamburger {
  display: flex;
  cursor: pointer;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.burger {
  width: 100%;
  height: 3px;
  background-color: rgb(197, 165, 37);
  transition: all 0.3s ease;
}

.hamburger.open .burger1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .burger2 {
  opacity: 0;
}

.hamburger.open .burger3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* .drawerPaper {
  background-color: #3f51b5;
  color: #ffffff;
  width: 20%;
} */

@media (max-width: 768px) {
  .hamburger-container {
  position: relative;
  padding-top: 2px;
  padding-left: 10%;
  }

  .hamburger {
    display: flex;
    /* width: 25px;
    height: 25px; */
  }

  .navbar {
  padding: 1% 2%;
  margin-right: 0%;
  justify-content: space-between;
}

  .nav-link {
    font-size: 0.67cap;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 3px;
    font-size: 24px;
    cursor: pointer;
  }
  .drawerPaper {
    /* background-color: #3f51b5; */
    /* color: #ffffff; */
    width: 50%;
  }
}



@media (max-width: 480px) {
  .hamburger {
    display: flex;
  }

  .navbar {
  padding: 1% 2%;
  margin-right: 0%;
}

  .nav-link {
    font-size: 0.67cap;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    /* padding-right: 3px; */
    font-size: 24px;
    cursor: pointer;
  }

  .drawerPaper {
  /* padding: 20px; */
  /* background-color: #3f51b5; */
  color: #ffffff;
  width: 50%;
  /* max-height: 40%; */
}
}

